<template>
  <CareForm></CareForm>
</template>

<script>
import CareForm from "@/components/care-form.vue";
export default {
  components: { CareForm },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style lang="css" scoped></style>
